import './App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from './components/Footer';
import { lazy, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from './context/UserContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFish, faPepperHot, faWater, faLeaf, faSpa, faHandRock, faCrown, faBookOpen, faSchool, faHeart } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga4';
import ContextModal from './context/ContextModal';
import io from 'socket.io-client';
import { hotjar } from 'react-hotjar';

library.add(faFish, faPepperHot, faWater, faLeaf, faSpa, faHandRock, faCrown, faBookOpen, faSchool, faHeart );

const availableLanguages = ['en', 'id'];

const AppRoutes = lazy(() => import('./AppRoutes'));
const Navbar = lazy(() => import('./components/Navbar'))

const socket = io(process.env.REACT_APP_BASEURL);

function App() {
  const { i18n } = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  const [userContext, setUserContext] = useContext(UserContext);

  const languageChanger = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('id');
      navigate(location.pathname.slice(-1) === '/' ? `${location.pathname}id` : `${location.pathname}/id`);
    } else {
      i18n.changeLanguage('en');
      let pathArr = location.pathname.split('/');
      if (availableLanguages.includes(pathArr[pathArr.length - 1])) {
        pathArr.pop();
        navigate(pathArr.join('/'));
      }
    }
  }

  useEffect(() => {
    hotjar.initialize(3288739, 6);
  }, [])

  useEffect(() => {
    ReactGA.initialize("G-0WCV6SXX2Y");
    ReactGA.send({hitType: "pageview", page: location.pathname + location.search });

    const pathArr = location.pathname.split('/');
    if (availableLanguages.includes(pathArr[pathArr.length - 1])) {
      i18n.changeLanguage(pathArr[pathArr.length - 1]);
    } else {
      i18n.changeLanguage('en')
    }
  }, [location, i18n])

  const verifyUser = useCallback(() => {
    fetch("/api/users/refreshToken", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }).then(async response => {
      if (response.ok) {
        const data = await response.json();

        socket.emit('login', {userId: data.user._id });
        setUserContext(oldValues => {
          return { ...oldValues, token: data.token, user: data.user }
        })
      } else {
        setUserContext(oldValues => {
          return { ...oldValues, token: null, user: null }
        })
      }
      // call refreshToken every 5 minutes to renew the authentication token.
      setTimeout(verifyUser, 5 * 60 * 1000)
    })
  }, [setUserContext])

  useEffect(() => {
    verifyUser();
  }, [verifyUser])

  /**
   * Sync logout across tabs
   */
   const syncLogout = useCallback(event => {
    if (event.key === "logout") {
      socket.emit('logout', {userId: userContext.user._id} );
      // If using react-router-dom, you may call history.push("/")
      window.location.reload();
    }
  }, [userContext.user?._id])

  useEffect(() => {
    window.addEventListener("storage", syncLogout)
    return () => {
      window.removeEventListener("storage", syncLogout)
    }
  }, [syncLogout])

  return (
    <div className='main-container'>
      <Navbar languageChanger={languageChanger} />
      <AppRoutes />
      <Footer languageChanger={languageChanger} />
      {userContext.modal && <ContextModal />}
    </div>
  );
}

export default App;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import { format as formatDate, isDate } from "date-fns";
import { enGB, id } from "date-fns/locale";

const locales = { enGB, id };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      format: (value, format, lng) => {
        if (isDate(value)) {
            const locale = locales[lng];
            return formatDate(value, format, { locale });
        }
      },
      escapeValue: false // react already safes from xss
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
  });

  export default i18n;
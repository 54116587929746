import { useContext } from "react";
import { UserContext } from "./UserContext";
import { Icon } from "../data/Icon";
export default function ContextModal() {
  const [userContext, setUserContext] = useContext(UserContext);

  const closeWindow = e => {
    setUserContext(old => {
      return {...old, modal: null, modalTone: null}
    })
  }

  const clickBackground = e => {
    if (e.target === e.currentTarget) {
      closeWindow();
    }
  }

  return (
    <div className="modal-back" onClick={clickBackground}>
      <div className="modal-form modal-with-header">
        <div className={userContext.modalTone === 'positive' ? "modal-header" : "modal-header modal-red"}>
          <div className="modal-icon">
            <Icon />
          </div>
          <h1>
            {userContext.modalTone === 'positive' ? 'Awesome!' : 'Heck!'}
          </h1>
        </div>
        <div className="modal-close" onClick={closeWindow}></div>
        <div className="modal-body">
          <p>{userContext.modal}</p>
          <button className="vrib-button" onClick={closeWindow}>{userContext.modalTone === 'positive' ? 'OK, great' : 'Understood'}</button>
        </div>
      </div>
    </div>
  )
}
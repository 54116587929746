import {FaWhatsapp} from 'react-icons/fa'
import {FiMail} from 'react-icons/fi'
import whiteLogo from '../media/VRIB-logo-white.png'
import { useTranslation } from 'react-i18next'
import ind from '../media/indonesia.svg';
import eng from '../media/english.svg';
import { useContext, useState, useEffect, useCallback } from 'react';
import { UserContext } from '../context/UserContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faMoneyBill);

const Footer = ({languageChanger}) => {
  const {t, i18n} = useTranslation();
  const [userContext, setUserContext] = useContext(UserContext);
  const [langHovered, setLangHovered] = useState(false);
  const [currencyHovered, setCurrencyHovered] = useState(false);

  const languageFlag = (lang, flag) => (
    <div style={{display: 'inline-block'}}>
      <img src={flag} alt="Country flag" style={{height: '12px', marginRight: '0.5rem', boxShadow: '1px 2px 2px 1px #00000020'}} />
      {lang.toUpperCase()}
    </div>
  )

  const getCurrency = useCallback(async () => {
    try {
      const fetchOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const response = await fetch('/api/exchange', fetchOptions);
      const value = await response.json();
      if (response.ok) {
        const currency = {
          type: 'USD',
          value: value.result.usd
        }
        setUserContext(old => {
          return {...old, currency }
        })
      } else {
        setUserContext(old => {
          return {...old, modal: 'There was an error, please try again or contact us.', modalTone: 'negative'}
        })
      }
    } catch (err) {
      setUserContext(old => {
        return {...old, modal: 'There was an error, please try again or contact us.', modalTone: 'negative'}
      })
    }
  }, [setUserContext])

  useEffect(() => {
    typeof userContext.currency === 'undefined' &&
    getCurrency();
  }, [getCurrency, userContext.currency])

  return (
    <div className="footer">
      <div className="footer-elements">
        <div className="footer-left">
          <h2>{t('footer.about')}</h2>
          <p>{t('footer.aboutDetails')}</p>
          <p>{t('footer.tryOut')}</p>
          <img src={whiteLogo} alt="VRIB white logo" />
          <p className='footer-copyright'>{t('footer.copyright', {date: new Date()})}</p>
        </div>
        <div className="footer-center">
          <h2>{t('footer.contact')}</h2>
          <p><FaWhatsapp /><a href="tel:+6281290363619">+62 878 5085 2457</a></p>
          <p><FiMail /><a href="mailto:villarentalsinbali@gmail.com">villarentalsinbali@gmail.com</a></p>
        </div>
        <div className="footer-right">
          <h2>{t('footer.language')}</h2>
          <span style={{color: 'white', cursor: 'pointer', marginBottom: '0.5rem', display: 'block', position: 'relative'}} className="lang" onClick={languageChanger} onMouseEnter={() => setLangHovered(true)} onMouseLeave={() => setLangHovered(false)} >
            {i18n.language === 'en' ? languageFlag('id', ind) : languageFlag('en', eng)}
            {langHovered &&
              <div style={{position: 'absolute', padding: '0.5rem', backgroundColor: 'var(--vrib-light)', zIndex: 9}}>
                Change language to: {i18n.language === 'en' ? 'ID' : 'ENG'}
              </div>
            }
          </span>
        </div>
      </div>
    </div>
  )
}

export default Footer;